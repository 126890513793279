import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames';

import IntegrationTile from './IntegrationTile.js';
import icon from './everreal@2x.png';
import styles from './EverrealIntegrationTile.module.scss';

export default function EverrealIntegrationTile() {
  return (
    <IntegrationTile
      icon={<img src={icon} alt="EverReal Logo" width="48px" height="48px" />}
      title="EverReal">
      <p className={classNames(text_styles.body2_left, styles.description)}>
        Nutze die Getmomo Integration um neue Mieter in Everreal automatisch zu
        Getmomo einzuladen.
      </p>
      <a
        href="https://www.getmomo.de/integrationen/everreal/"
        target="_blank"
        className={text_styles.textlink_1_2_bold_primary_left}
        rel="noreferrer">
        weitere Infos
      </a>
    </IntegrationTile>
  );
}
