import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import styles from './RadioToggle.module.scss';

export default function RadioToggle({
  name,
  option_1,
  option_1_label,
  option_2,
  option_2_label,
  watch,
  onChange,
}) {
  const value = watch(name);
  const option_1_selected = value === option_1;
  const option_2_selected = value === option_2;

  return (
    <div className={styles.radio_container}>
      <label
        className={classNames(
          text_styles.body2_centered,
          styles.radio_label,
          option_1_selected && styles.radio_label_selected,
        )}>
        <input
          className={styles.radio_button}
          type="radio"
          name={name}
          value={option_1}
          onChange={onChange}
        />
        {option_1_label}
      </label>
      <label
        className={classNames(
          text_styles.body2_centered,
          styles.radio_label,
          option_2_selected && styles.radio_label_selected,
        )}>
        <input
          className={styles.radio_button}
          type="radio"
          name={name}
          value={option_2}
          onChange={onChange}
        />
        {option_2_label}
      </label>
    </div>
  );
}
