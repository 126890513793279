import {useCallback, useState, useMemo} from 'react';

/**
 * @typedef FilterSpec
 * @property {string} column The column to apply the filter to
 * @property {string|string[]} filter The filter value(s) to apply
 */

/**
 * @typedef UseFilters
 * @property {Array<FilterSpec>} filters The current state of the table filters
 * @property {Array<object>} query_filters The constructed filters to send to the backend
 * @property {Function} setFilter A function to toggle filter values for a column
 * @property {Function} resetFilters A function to reset all filters to their initial state
 * @property {Function} isFilterActive A function to check if a column's filters are active
 */

/**
 * A custom hook for managing table filters.
 *
 * @param {object} params - The parameters for the hook.
 * @param {Array<object>} params.columns - The column definitions, each containing filter metadata.
 * @param {Array<FilterSpec>} [params.default_filters=[]] - The default filter state.
 * @returns {UseFilters} An object containing filter-related state and functions.
 */
export function useFilters({columns, default_filters = []}) {
  const [tableFilters, setTableFilters] = useState(default_filters);

  /**
   * Constructs the filters to send to the backend based on the current table filter state.
   *
   * @type {object}
   */
  const query_filters = useMemo(() => {
    if (!columns) return {op: '$and', conditions: []};

    const conditions = tableFilters
      .map(({column, filter}) => {
        // Determine if the filter is an array or a single value
        const isArray = Array.isArray(filter);

        if (!filter || (isArray && filter.length === 0)) {
          // If no filters are selected, force no results
          return {
            column,
            op: '$in',
            value: [], // Backend interprets this as returning no rows
          };
        }

        // If filter is a string, use $eq
        if (!isArray) {
          return {
            column,
            op: '$eq',
            value: filter,
          };
        }

        // If filter is an array, handle as follows
        const columnDef = columns.find((col) => col.name === column);
        const allOptions = columnDef?.filters?.map((f) => f.value) || [];

        // Check if all options are selected
        const allOptionsSelected =
          filter.length === allOptions.length &&
          allOptions.every((option) => filter.includes(option));

        // If all options are selected, exclude this column from conditions
        if (allOptionsSelected) {
          return undefined;
        }

        // Construct the query filter for selected options
        return {
          column,
          op: '$in',
          value: filter,
        };
      })
      .filter(Boolean); // Remove undefined values

    return {
      op: '$and', // Use as the default operator
      conditions,
    };
  }, [tableFilters, columns]);

  /**
   * Sets filter values for a specified column. If a value is selected, it deselects it;
   * if it is deselected, it selects it.
   *
   * @param {string} column - The column for which to toggle filters.
   * @param {Array<string>} values - The filter values to toggle.
   */
  const setFilter = useCallback(
    (column, values) => {
      setTableFilters((prev_filters) => {
        const columnFilters = prev_filters.find((f) => f.column === column);

        if (columnFilters) {
          const updatedFilters = columnFilters.filter.some((value) =>
            values.includes(value),
          )
            ? columnFilters.filter.filter((value) => !values.includes(value)) // Remove unselected values
            : [...columnFilters.filter, ...values]; // Add selected values

          // If the updated filters are empty, return the default state for the query
          if (updatedFilters.length === 0) {
            return prev_filters.map((f) =>
              f.column === column
                ? {column, filter: []} // Empty filter for the column
                : f,
            );
          }

          return prev_filters.map((f) =>
            f.column === column ? {column, filter: updatedFilters} : f,
          );
        }

        // Add new filters for the column
        return values.length > 0
          ? [...prev_filters, {column, filter: values}]
          : prev_filters; // Do not add if values are empty
      });
    },
    [setTableFilters],
  );

  /**
   * Resets the table filters to their initial state.
   */
  const resetFilters = useCallback(() => {
    setTableFilters(default_filters);
  }, [default_filters]);

  /**
   * Checks if a column's filters are active (different from the initial state).
   *
   * @param {string} column - The column to check.
   * @returns {boolean} `true` if the current filters differ from the default filters, otherwise `false`.
   */
  const isFilterActive = useCallback(
    (column) => {
      const current =
        tableFilters.find((f) => f.column === column)?.filter ?? [];
      const defaultFilter =
        default_filters.find((f) => f.column === column)?.filter ?? [];
      return (
        current.length !== defaultFilter.length ||
        !current.every((val) => defaultFilter.includes(val))
      );
    },
    [tableFilters, default_filters],
  );

  return {
    filters: tableFilters,
    query_filters,
    setFilter,
    resetFilters,
    isFilterActive,
  };
}
