import React, {useState} from 'react';
import {useForm, Controller} from 'react-hook-form';
import PhoneNumberInput from 'shared-web/components/phoneNumberInput/PhoneNumberInput.js';
import Input from 'shared-web/components/Input.js';
import {BAD_REQUEST} from 'shared/api.js';
import Button from 'shared-web/components/Button.js';
import {setFormErrors} from 'shared-web/effects.js';
import Select from 'shared-web/components/Select';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames';
import {useHistory} from 'react-router-dom';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {MANAGER_PATHS} from 'shared/constants.js';
import {useNotify} from 'shared-web/NotifyProvider.js';

import {createPropertyOwner} from '../../actions.js';
import {handleError, alert, closeDialog, confirmClose} from '../../effects.js';
import {
  country_select_options,
  gender_select_options,
} from '../../lib/utils.js';
import RadioToggle from '../../components/RadioToggle.js';

import styles from './PropertyOwnerDialog.module.scss';
import CoownerFormSection from './CoownerFormSection.js';

export default function CreatePropertyOwnerDialog({
  onSave,
  onDeletePropertyOwner,
  ...props
}) {
  const [visible, setVisible] = useState(true);
  const history = useHistory();
  const {notify} = useNotify();

  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
    defaultValues: {type: 'company', has_coowner: false},
  });

  const onSubmit = handleSubmit((fields) =>
    submit({fields, onSave, setError, setVisible, notify}),
  );

  const footer = (
    <div className={styles.footer}>
      <Button
        title="Eigentümer erstellen"
        loading={isSubmitting}
        onClick={onSubmit}
        disabled={!isDirty}
      />
    </div>
  );

  const type = watch('type');
  const company_selected = type === 'company';
  const individual_selected = type === 'individual';

  return (
    <Dialog
      title="Eigentümer erstellen"
      show={visible}
      className={styles.dialog}
      footer={footer}
      onHide={() => confirmClose({isDirty})}
      onExited={() => {
        if (onSave) return;
        history.push(MANAGER_PATHS.PropertyOwnersScreen);
      }}
      {...props}>
      <Controller
        control={control}
        name="type"
        render={({field: {onChange, name}}) => (
          <RadioToggle
            name={name}
            option_1="company"
            option_1_label="Firma"
            option_2="individual"
            option_2_label="Privatperson"
            onChange={onChange}
            watch={watch}
          />
        )}
      />

      {company_selected && <CompanyForm errors={errors} register={register} />}
      {individual_selected && (
        <IndividualForm
          errors={errors}
          register={register}
          watch={watch}
          setValue={setValue}
        />
      )}

      <div className={styles.separator} />
      <div className={classNames(text_styles.body1_bold_left, styles.title)}>
        Optional Angaben:
      </div>

      {company_selected && (
        <div className={styles.row}>
          <Input
            label="Ansprechpartner Vorname"
            error={errors.first_name?.message}
            className={styles.margin_right}
            {...register('first_name')}
          />
          <Input
            label="Ansprechpartner Nachname"
            error={errors.last_name?.message}
            {...register('last_name')}
          />
        </div>
      )}

      <div className={styles.row}>
        <Controller
          control={control}
          name="phone_number"
          defaultValue=""
          render={({field: {value, onChange, name}}) => (
            <PhoneNumberInput
              value={value}
              onChange={onChange}
              label="Telefonnummer"
              error={errors[name]?.message}
              className={classNames(styles.margin_right, styles.last_row)}
            />
          )}
        />
        <Input
          label="E-mail"
          error={errors.email_address?.message}
          className={styles.last_row}
          {...register('email_address')}
        />
      </div>
    </Dialog>
  );
}

async function submit({fields, onSave, setError, setVisible, notify}) {
  let updated;

  try {
    updated = await createPropertyOwner(fields);
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (err.data?.length) {
        setFormErrors({
          setError,
          errors: err.data,
        });
      } else {
        alert({
          title: 'Die Angaben sind unvollständig',
          text: 'Bitte tragen Sie alle Daten ein. Sollten Ihnen verbindliche Angaben fehlen, können Sie den Vorgang abbrechen und den "neuen Eigentümer" später hinzufügen.',
        });
      }
    } else {
      handleError(err);
    }
    return;
  }

  notify({
    text: 'Der Eigentümer wurde hinzugefügt',
  });
  if (onSave) {
    await onSave(updated.id);
    closeDialog('create_property_owner');
  }
  setVisible(false);
}

function CompanyForm({errors, register}) {
  return (
    <>
      <div className={classNames(text_styles.body1_bold_left, styles.title)}>
        Angaben zur Firma
      </div>

      <Input
        placeholder="z. B. Schmitt Immobilien GmbH"
        label="Firma und Rechtsform"
        error={errors.company_name?.message}
        {...register('company_name')}
      />

      <div className={styles.row}>
        <Input
          label="Straße"
          error={errors.street_name?.message}
          className={styles.margin_right}
          {...register('street_name')}
        />
        <Input
          label="Hausnummer"
          error={errors.street_number?.message}
          {...register('street_number')}
        />
      </div>

      <div className={styles.row}>
        <Input
          label="PLZ"
          error={errors.postal_code?.message}
          className={styles.margin_right}
          {...register('postal_code')}
        />
        <Input
          label="Ort"
          error={errors.region?.message}
          {...register('region')}
        />
      </div>

      <Select
        label="Land"
        options={country_select_options}
        defaultValue={'DE'}
        {...register('country')}
      />
    </>
  );
}

function IndividualForm({errors, register, watch, setValue}) {
  return (
    <>
      <div className={styles.row}>
        <Input
          label="Vorname"
          error={errors.first_name?.message}
          className={styles.margin_right}
          {...register('first_name')}
        />
        <Input
          label="Nachname"
          error={errors.last_name?.message}
          {...register('last_name')}
        />
      </div>

      <div className={styles.row}>
        <Input
          label="Straße"
          error={errors.street_name?.message}
          className={styles.margin_right}
          {...register('street_name')}
        />
        <Input
          label="Hausnummer"
          error={errors.street_number?.message}
          {...register('street_number')}
        />
      </div>

      <div className={styles.row}>
        <Input
          label="PLZ"
          error={errors.postal_code?.message}
          className={styles.margin_right}
          {...register('postal_code')}
        />
        <Input
          label="Ort"
          error={errors.region?.message}
          {...register('region')}
        />
      </div>

      <div className={styles.row}>
        <Select
          label="Land"
          options={country_select_options}
          {...register('country')}
        />
        <div className={styles.gutter} />
        <Select
          label="Geschlecht"
          options={gender_select_options}
          error={errors.gender?.message}
          {...register('gender')}
        />
      </div>

      <CoownerFormSection
        errors={errors}
        register={register}
        watch={watch}
        setValue={setValue}
      />
    </>
  );
}
