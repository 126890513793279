import React from 'react';
import Button from 'shared-web/components/Button.js';

import styles from './Navigation.module.scss';

export default function Navigation({
  loading,
  disabled,
  submit_step,
  is_last_step,
  submit_action_title,
}) {
  return (
    <div className={styles.footer}>
      <Button
        title={is_last_step ? submit_action_title : 'Weiter'}
        loading={loading}
        disabled={disabled}
        onClick={submit_step}
      />
    </div>
  );
}
