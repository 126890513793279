import React from 'react';
import ReactDOM from 'react-dom';
import 'shared-web/fonts.css';
// Import global scoped styles before App.js
// so that component css files and modules have priority
// and override them
import 'shared-web/bootstrap.scss';
import './index.scss';

import App from './App.js';
import {reportError} from './effects.js';

const {REACT_APP_CHATWOOT_TOKEN} = process.env;

// eslint-disable-next-line func-names
(function (d, t) {
  window.chatwootSettings = {
    type: 'expanded_bubble',
    launcherTitle: 'Chat',
    locale: 'de',
  };

  const BASE_URL = 'https://chatwoot.getmomo.net';
  const g = d.createElement(t),
    s = d.getElementsByTagName(t)[0];
  g.src = `${BASE_URL}/packs/js/sdk.js`;
  g.defer = true;
  g.async = true;
  g.addEventListener('load', () => {
    window.chatwootSDK.run({
      websiteToken: REACT_APP_CHATWOOT_TOKEN,
      baseUrl: BASE_URL,
    });
  });

  document.addEventListener('DOMContentLoaded', () => {
    s.parentNode.insertBefore(g, s);
  });

  window.addEventListener('chatwoot:error', reportError);
})(document, 'script');

// This is a hacky workaround to get the manager app to work with google translate
// see https://github.com/get-momo/issues/issues/2523
/* eslint-disable func-names, prefer-rest-params, no-console */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot remove a child from a different parent',
          child,
          this,
        );
      }
      return child;
    }
    return Reflect.apply(originalRemoveChild, this, arguments);
  };

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error(
          'Cannot insert before a reference node from a different parent',
          referenceNode,
          this,
        );
      }
      return newNode;
    }
    return Reflect.apply(originalInsertBefore, this, arguments);
  };
}
/* eslint-enable */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
