import text_styles from 'shared-web/styles/text_styles.module.scss';
import Placeholder from 'shared-web/components/combobox/Placeholder.js';

import {showDialog} from '../effects.js';

import Combobox from './combobox/Combobox.js';
import NewEntryButton from './combobox/NewEntryButton.js';

export default function SelectRentalContract({
  value,
  onChange,
  selectedItemListener,
  label,
  error,
  rpc_method,
  show_type_toggle,
}) {
  return (
    <Combobox
      value={value}
      onChange={onChange}
      selectedItemListener={selectedItemListener}
      label={label}
      error={error}
      placeholder="bitte hier klicken"
      rpc_method={rpc_method}
      DropdownItem={DropdownItem}
      itemToString={itemToString}
      button_new={
        <NewEntryButton
          title="Mieter erstellen"
          dialog="create_rental_contract"
          onChange={(entity_id, ...args) => {
            selectedItemListener?.(entity_id);
            onChange(entity_id, ...args);
          }}
          showDialog={showDialog}
          dialog_props={{show_type_toggle}}
        />
      }
      NoResultsPlaceholder={NoResultsPlaceholder}
      NoSearchResultsPlaceholder={NoSearchResultsPlaceholder}
    />
  );
}

function DropdownItem({item}) {
  return (
    <p className={text_styles.body1_bold_left}>{item.tenant_display_name}</p>
  );
}

function itemToString(item) {
  return item?.tenant_display_name || '';
}

function NoResultsPlaceholder() {
  return (
    <Placeholder>
      <p>Es wurden noch keine Mieter angelegt.</p>
      <p>
        Sie können einen „Mieter erstellen“ und diesen später im Menüpunkt
        „Kautionsverwaltung“ verwalten.
      </p>
    </Placeholder>
  );
}

function NoSearchResultsPlaceholder({search_query}) {
  return (
    <Placeholder>
      <p>Wir konnten keine Mieter beginnend mit „{search_query}“ finden.</p>
      <p>
        Sie können ein „Mieter erstellen“ und diese später im Menüpunkt
        „Kautionsverwaltung“ verwalten.
      </p>
    </Placeholder>
  );
}
