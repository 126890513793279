import {Controller} from 'react-hook-form';
import Input from 'shared-web/components/Input.js';
import PriceInput from 'shared-web/components/PriceInput.js';
import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import {alert} from '../../effects.js';

import styles from './RentalData.module.scss';

export default function RentalData({errors, register, control}) {
  return (
    <div>
      <Controller
        control={control}
        name="deposit_amount_cents"
        render={({field: {value, onChange, name}}) => (
          <PriceInput
            value={value}
            onChange={onChange}
            label="Kaution"
            suffix={' €'}
            error={errors[name]?.message}
            name={name}
          />
        )}
      />

      <div className={styles.row}>
        <Input
          label="Straße"
          error={errors.street_name?.message}
          {...register('street_name')}
        />
        <Input
          label="Hausnummer"
          error={errors.street_number?.message}
          {...register('street_number')}
        />
      </div>

      <div className={styles.row}>
        <Input
          type="text"
          label="PLZ"
          placeholder="..."
          error={errors.postal_code?.message}
          {...register('postal_code')}
        />
        <Input
          label="Ort"
          error={errors.region?.message}
          {...register('region')}
        />
      </div>
      <div className={styles.row}>
        <div className={styles.country_container}>
          <div className={classNames(text_styles.caption_left, styles.label)}>
            Land
          </div>
          <div
            className={styles.country_select}
            onClick={() => {
              alert({
                title:
                  'Wir bauen unseren Service aus, aktuell gilt dieser jedoch nur in Deutschland',
                text: 'Bitte senden Sie eine E-Mail an partnersupport@getmomo.de, falls Sie unseren Service auch in anderen Ländern nutzen möchten. Damit wir unser Angebot für Sie entsprechend optimieren können.',
              });
            }}>
            Deutschland
          </div>
        </div>
      </div>
    </div>
  );
}
