import React from 'react';
import classNames from 'classnames/bind';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import LegalLinks from 'shared-web/components/layout/LegalLinks.js';

import {ReactComponent as MomoLogoBright} from '../../assets/logo_bright.svg';

import styles from './AuthenticationScreen.module.scss';

export default function AuthenticationScreen({children}) {
  return (
    <div className={styles.container}>
      <div className={styles.video_container}>
        <video className={styles.video} autoPlay muted loop>
          <source
            src={`${process.env.PUBLIC_URL}/momo-login-logout.mp4`}
            type="video/mp4"
          />
        </video>
        <div className={styles.video_overlay} />
      </div>
      <div className={styles.content}>
        {children}
        <div className={styles.footer}>
          <div className={styles.help}>
            <p className={text_styles.body1_bright_bold_centered}>
              Probleme beim Anmelden?
            </p>
            <p className={text_styles.body1_bright_bold_centered}>
              Sie möchten sich registrieren?
            </p>
          </div>
          <p className={text_styles.body1_bright_centered}>
            Wir helfen gerne unter{' '}
            <a
              href="tel:+493091734990"
              className={classNames(
                text_styles.body1_bright_centered,
                styles.phone,
              )}>
              +49 30 91734990
            </a>
          </p>
          <a href="https://getmomo.de">
            <MomoLogoBright alt="Das Momo Logo" className={styles.logo} />
          </a>
          <LegalLinks className={text_styles.caption_bright_centered} />
        </div>
      </div>
    </div>
  );
}
