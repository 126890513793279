import React from 'react';
import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import styles from './RadioButton.module.scss';

export default function RadioButton({
  name,
  value,
  label,
  checked,
  onChange,
  isBright = false,
  className,
}) {
  return (
    <label
      className={classNames(styles.label, className)}
      htmlFor={`radio-${name}-${value}`}>
      <div className={styles.radio_container}>
        <span
          className={classNames(
            styles.radio_border,
            checked &&
              (isBright
                ? styles.radio_border_selected_bright
                : styles.radio_border_selected_dark),
          )}
        />
        <span
          className={classNames(
            styles.radio_circle,
            checked &&
              (isBright
                ? styles.radio_circle_selected_bright
                : styles.radio_circle_selected_dark),
          )}
        />
        <span
          className={isBright ? styles.radio_hover_bright : styles.radio_hover}
        />
        <input
          className={styles.radio_button}
          name={name}
          type="radio"
          value={value}
          id={`radio-${name}-${value}`}
          onChange={onChange}
        />
      </div>
      <p
        className={classNames(
          isBright ? text_styles.body2_bright : text_styles.body2,
          styles.radio_text,
        )}>
        {label}
      </p>
    </label>
  );
}
