import React from 'react';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames/bind';

import styles from './ResetPasswordSent.module.scss';

export default function ResetPasswordSent() {
  return (
    <>
      <p className={text_styles.h1_bright_bold_centered}>Vielen Dank!</p>
      <p
        className={classNames(
          text_styles.body1_bright_centered,
          styles.success_message,
        )}>
        Wir haben Ihnen einen Link zum Erstellen eines neuen <br />
        Passwortes per E-Mail gesendet.
      </p>
      <p className={text_styles.body1_bright_centered}>
        Bitte überprüfen Sie Ihren Posteingang
      </p>
    </>
  );
}
