import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import styles from './Progressbar.module.scss';

export default function ProgressBar({current_step, steps, className}) {
  const percent = (current_step && (current_step / steps.length) * 100) || 0;

  return (
    <>
      <div>
        <div
          className={classNames(styles.progress_bar, className)}
          style={{width: `${percent}%`}}
        />
        <div className={styles.inactive_bar} />
      </div>
      <div className={styles.row}>
        {steps.map(({id, label}, step_index) => (
          <div key={id} className={styles.hint_container}>
            <p
              className={classNames(
                text_styles.body3,
                current_step >= step_index + 1 && styles.primary_color,
              )}>
              {label}
            </p>
          </div>
        ))}
      </div>
    </>
  );
}
