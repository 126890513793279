import {useState} from 'react';
import {useDebouncedCallback} from 'use-debounce';
import Icon from 'shared-web/components/Icon.js';
import IconButton from 'shared-web/components/IconButton.js';
import {ReactComponent as DeleteSvg} from 'shared-web/components/icon-delete-text.svg';
import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import {ReactComponent as SearchSvg} from './icon-search.svg';
import styles from './SearchInput.module.scss';

export default function SearchInput({onValue}) {
  const [input_value, setInputValue] = useState('');
  const [has_focus, setHasFocus] = useState(false);
  const debounced = useDebouncedCallback(onValue, 250);

  const show_clear_button = input_value.length > 0;
  const show_search_icon = !show_clear_button && !has_focus;

  function clearInput() {
    setInputValue('');
    onValue('');
  }

  return (
    <div className={styles.wrapper}>
      <input
        value={input_value}
        type="text"
        placeholder="Liste durchsuchen"
        className={classNames(text_styles.hint, styles.input)}
        onChange={(e) => {
          setInputValue(e.target.value);
          debounced(e.target.value);
        }}
        onFocus={() => {
          setHasFocus(true);
        }}
        onBlur={() => {
          setHasFocus(false);
        }}
      />
      {show_search_icon && (
        <Icon className={styles.search_icon}>
          <SearchSvg />
        </Icon>
      )}
      {show_clear_button && (
        <IconButton onClick={clearInput} className={styles.delete_icon}>
          <DeleteSvg />
        </IconButton>
      )}
    </div>
  );
}
