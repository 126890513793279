import React from 'react';
import classNames from 'classnames';
import Input from 'shared-web/components/Input.js';
import Button from 'shared-web/components/Button.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import {closeDialog, openTenantReport} from '../../effects.js';
import Dialog from '../../components/dialog/Dialog.js';
import {getGermanGender} from '../../lib/utils.js';

import styles from './PropertyOwnerDialog.module.scss';

export default function PropertyOwnerDialogReadOnly({
  property_owner,
  ...props
}) {
  const footer = (
    <div className={styles.footer}>
      <>
        <Button
          title="Bericht generieren"
          onClick={() => openTenantReport(property_owner)}
          disabled={property_owner.number_of_active_deposit_guarantees <= 0}
        />
        <div className={styles.status_row}>
          <div className={text_styles.body1_bold_left}>Verknüpfte Mieter:</div>
          <div className={text_styles.body2}>
            {property_owner.number_of_active_deposit_guarantees}
          </div>
        </div>
      </>
    </div>
  );

  const {
    type,
    company_name,
    first_name,
    last_name,
    postal_code,
    region,
    street_name,
    street_number,
    country,
    email_address,
    phone_number,
    gender,
  } = property_owner;
  const company_selected = type === 'company';
  const individual_selected = type === 'individual';
  const title = `Eigentümer ${
    type === 'company' ? '(Firma)' : '(Privatperson)'
  } `;

  return (
    <Dialog
      title={title}
      className={styles.dialog}
      footer={footer}
      onHide={() => closeDialog('property_owner_read_only')}
      {...props}>
      {company_selected && (
        <>
          <div className={styles.row}>
            <Input
              defaultValue={company_name}
              placeholder="z. B. Schmitt Immobilien GmbH"
              label="Firma und Rechtsform"
              className={styles.margin_right}
              readOnly
            />
            <Input
              label="Ansprechpartner"
              defaultValue={`${first_name} ${last_name}`}
              readOnly
            />
          </div>
        </>
      )}
      {individual_selected && (
        <div className={styles.row}>
          <Input
            defaultValue={first_name}
            label="Vorname"
            className={styles.margin_right}
            readOnly
          />
          <Input defaultValue={last_name} label="Nachname" readOnly />
        </div>
      )}

      <div className={styles.row}>
        <Input
          defaultValue={street_name}
          label="Straße"
          className={styles.margin_right}
          readOnly
        />
        <Input defaultValue={street_number} label="Hausnummer" readOnly />
      </div>

      <div className={styles.row}>
        <Input
          defaultValue={postal_code}
          label="PLZ"
          className={styles.margin_right}
          readOnly
        />
        <Input defaultValue={region} label="Ort" readOnly />
      </div>
      <div className={styles.row}>
        <Input
          label="Land"
          defaultValue={country}
          className={styles.margin_right}
          readOnly
        />
        <Input defaultValue={email_address} label="E-mail" readOnly />
      </div>

      <div className={styles.row}>
        <Input
          label="Telefonnummer"
          defaultValue={phone_number || ''}
          className={classNames(
            individual_selected && styles.margin_right,
            styles.last_row_read_only,
          )}
          readOnly
        />
        {individual_selected && (
          <Input
            label="Geschlecht"
            defaultValue={getGermanGender(gender) || ''}
            readOnly
            className={styles.last_row_read_only}
          />
        )}
      </div>
    </Dialog>
  );
}
