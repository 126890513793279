import terms_and_conditions_pdf from 'shared/assets/termsheets/2024-08.Nutzungsbedingungen.pdf';
import deposit_guarantee_special_conditions_pdf from 'shared/assets/termsheets/2023-07.Burgschafts-Bedingungen.pdf';
import cash_deposit_special_conditions_pdf from 'shared/assets/termsheets/2024-08.Barkautions-Bedingungen.pdf';
import classNames from 'classnames';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {CONSENTS} from 'shared/constants.js';

import styles from './TermsModal.module.scss';

export default function TermsModal({consents}) {
  return (
    <div>
      <p className={styles.information_text}>
        Die aktualisierten Dokumente stehen Ihnen unter dem folgenden Link für
        Sie zum Download bereit:
      </p>

      <div className={styles.link_container}>
        {consents.map((consent) => (
          <a
            key={consent.consent_type}
            className={classNames(text_styles.body2, styles.link)}
            href={getConsentLink(consent.consent_type)}
            target="_blank"
            rel="noreferrer">
            {getConsentLabel(consent.consent_type)}
          </a>
        ))}
      </div>
    </div>
  );
}

function getConsentLink(consent_type) {
  switch (consent_type) {
    case CONSENTS.manager_terms_and_conditions: {
      return terms_and_conditions_pdf;
    }
    case CONSENTS.manager_deposit_guarantee_conditions: {
      return deposit_guarantee_special_conditions_pdf;
    }
    case CONSENTS.manager_cash_deposit_special_conditions: {
      return cash_deposit_special_conditions_pdf;
    }
    default: {
      return null;
    }
  }
}

function getConsentLabel(consent_type) {
  switch (consent_type) {
    case CONSENTS.manager_terms_and_conditions: {
      return 'Nutzungsbedingungen';
    }
    case CONSENTS.manager_deposit_guarantee_conditions: {
      return 'Bürgschafts-Bedingungen';
    }
    case CONSENTS.manager_cash_deposit_special_conditions: {
      return 'Barkautions-Bedingungen';
    }
    default: {
      return null;
    }
  }
}
