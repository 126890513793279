import {RPC} from 'shared/api.js';
import {initializeEffects} from 'shared-web/effects';
import {openExternal} from 'shared/utils.js';

import store from './store/store.js';
import {deleteDepositGuarantee as deleteDepositGuaranteeApi} from './actions.js';
import {isDeleteAllowed} from './lib/utils.js';

export const {alert, confirm, reportError, showGenericError, handleError} =
  initializeEffects(store);

export function showDialog(name, props) {
  store.dispatch({
    type: 'SHOW_DIALOG',
    name,
    props,
  });
}

export function closeDialog(name) {
  store.dispatch({type: 'HIDE_DIALOG', name});
}

export async function deleteDepositGuarantee({deposit_guarantee, notify}) {
  if (!isDeleteAllowed(deposit_guarantee)) {
    return alert({
      title: 'Laufende Anfrage',
      text: 'Der Vertrag kann erst gelöscht werden, nachdem die laufende Anfrage geklärt wurde. Bei Fragen wenden Sie sich bitte an partnersupport@getmomo.de',
    });
  }

  const {tenant_display_name, id} = deposit_guarantee;

  if (
    !(await confirm({
      title: `Möchten Sie ${tenant_display_name} wirklich endgültig aus der Liste entfernen?`,
      text: 'Mit dieser Person abgeschlosssene Verträge werden somit storniert, der Kautionsschutz wird aufgehoben und der Eintrag aus der Liste entfernt!',
      proceed_label: 'Ja jetzt löschen',
    }))
  ) {
    return;
  }

  try {
    await deleteDepositGuaranteeApi(id);
  } catch (err) {
    handleError(err);
    return;
  }

  notify({text: 'Der Vertrag wurde gelöscht.'});
  return true;
}

export async function openTenantReport(property_owner) {
  const {id: property_owner_id} = property_owner;
  let tenant_report_url;

  try {
    tenant_report_url = await RPC('getTenantReportURL', {property_owner_id});
  } catch (err) {
    return handleError(err);
  }

  openExternal(tenant_report_url);
}

export async function openCashDepositTransactionsReport(deposit_id) {
  let url;

  try {
    url = await RPC('getCashDepositTransactionsReportURL', {deposit_id});
  } catch (err) {
    return handleError(err);
  }

  openExternal(url);
}

export async function openTaxSummary(deposit_id, year) {
  let tax_summary_url;

  try {
    tax_summary_url = await RPC('getTaxSummaryURL', {
      deposit_id,
      year,
    });
  } catch (err) {
    return handleError(err);
  }
  openExternal(tax_summary_url);
}

export async function confirmClose({isDirty, afterClose = () => {}}) {
  if (!isDirty) {
    afterClose();
    return true;
  }

  if (
    !(await confirm({
      title: 'Möchten Sie den Vorgang abbrechen?',
      text: 'Dadurch gehen nicht gespeicherte Änderungen verloren.',
      abort_label: 'Ja, jetzt abbrechen',
      proceed_label: 'Nein, weiter bearbeiten',
    }))
  ) {
    afterClose();
    return true;
  }
  return false;
}

export async function deleteCashDeposit({cash_deposit, notify}) {
  if (cash_deposit.number_of_transactions > 0) {
    return alert({
      title: 'Eintrag nicht mehr löschbar.',
      text: 'Nach dem ersten Geldeingang oder ab der Video-Identifizierung bei einer Bürgschaft kann der Eintrag nicht mehr gelöscht werden.',
    });
  }

  const {tenant_1_display_name, id} = cash_deposit;

  if (
    !(await confirm({
      title: `Möchten Sie den Mieter ${tenant_1_display_name} wirklich aus der Liste entfernen?`,
      text: 'Wenn Sie diesen Mieter entfernen, wird der Eintrag aus der Liste gelöscht und das virtuelle Unterkonto geht verloren.',
      proceed_label: 'Jetzt löschen',
    }))
  ) {
    return;
  }

  try {
    await RPC('deleteCashDeposit', {deposit_id: id});
  } catch (err) {
    handleError(err);
    return;
  }

  notify({text: 'Der Mieter wurde gelöscht.'});
  store.dispatch({
    type: 'RENTAL_CONTRACT_DELETED',
  });
  return true;
}
