import classNames from 'classnames';
import IconButton from 'shared-web/components/IconButton.js';
import {formatIban} from 'shared/utils.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {ReactComponent as CopySvg} from 'shared-web/assets/copy.svg';
import {useNotify} from 'shared-web/NotifyProvider.js';
import {copyText} from 'shared-web/effects.js';

import styles from './IbanDisplay.module.scss';

export default function IbanDisplay({iban}) {
  const {notify} = useNotify();
  return (
    <div className={styles.iban_container}>
      <div>
        <p>
          <span
            className={classNames(text_styles.body1_bold_left, styles.label)}>
            IBAN:
          </span>
          <span className={styles.iban}>{formatIban(iban)}</span>{' '}
        </p>
      </div>
      <IconButton onClick={() => copyText('IBAN', iban, notify)}>
        <CopySvg />
      </IconButton>
    </div>
  );
}
