import {useCallback} from 'react';
import SharedCombobox from 'shared-web/components/combobox/Combobox';

export default function Combobox({
  label,
  value,
  onChange,
  selectedItemListener,
  readOnly,
  error = '',
  placeholder,
  DropdownItem,
  itemToString,
  NoResultsPlaceholder,
  NoSearchResultsPlaceholder,
  button_new,
  name,
  rpc_method,
  rpc_params = {},
  scrollable_search_result = false,
}) {
  const rpcParamsFromComboboxData = useCallback(
    ({value, input_value}) => {
      return {
        id_selected: value || undefined,
        q: input_value,
        ...rpc_params,
      };
    },
    [rpc_params],
  );

  return (
    <SharedCombobox
      label={label}
      value={value}
      onChange={onChange}
      selectedItemListener={selectedItemListener}
      readOnly={readOnly}
      error={error}
      placeholder={placeholder}
      DropdownItem={DropdownItem}
      itemToString={itemToString}
      NoResultsPlaceholder={NoResultsPlaceholder}
      NoSearchResultsPlaceholder={NoSearchResultsPlaceholder}
      button_new={button_new}
      name={name}
      rpc_method={rpc_method}
      rpcParamsFromComboboxData={rpcParamsFromComboboxData}
      scrollable_search_result={scrollable_search_result}
    />
  );
}
