import {useForm} from 'react-hook-form';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import {setFormErrors} from 'shared-web/effects.js';
import {toLocaleDate} from 'shared/utils.js';
import {useEffect, useState} from 'react';
import IconButton from 'shared-web/components/IconButton.js';
import {PROPERTY_TYPES, VALIDATION_ERROR} from 'shared/constants.js';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import {useNotify} from 'shared-web/NotifyProvider.js';

import {inviteTenant} from '../../actions.js';
import {handleError, alert, showDialog, confirmClose} from '../../effects.js';
import {useWizard} from '../../components/Wizard.js';
import {ReactComponent as ArrowLeftSvg} from '../../assets/icon_arrow_left.svg';
import ProgressBar from '../../components/ProgressBar.js';
import Navigation from '../../components/Navigation.js';

import styles from './TenantInvitationDialog.module.scss';
import TenantData from './TenantData.js';
import RentalData from './RentalData.js';
import PropertyOwnerData from './PropertyOwnerData.js';

const {REACT_APP_DEV_MODE} = process.env;

export default function TenantInvitationDialog({...props}) {
  const [visible, setVisible] = useState(true);
  const rental_contract = {};
  const {
    control,
    register,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      property_type: PROPERTY_TYPES.apartment,
    },
  });
  const {notify} = useNotify();

  const steps = [
    {id: 'tenant', label: 'Mieter'},
    !watch('tenant_provides_additional_data') && {
      id: 'property_owner',
      label: 'Eigentümer',
    },
    !watch('tenant_provides_additional_data') && {
      id: 'property',
      label: 'Mieteinheit',
    },
  ].filter((i) => !!i);

  const {
    step,
    next,
    previous,
    index_step: current_step,
    is_last_step,
    is_first_step,
  } = useWizard(steps);

  useEffect(() => {
    if (REACT_APP_DEV_MODE === 'true') {
      RPC('getDevelopmentFormState', {id: 'tenant-invitation'}).then((res) => {
        reset(res, {keepDefaultValues: true});
      });
    }
  }, [reset]);

  const {title, submit} = pages[step.id] || {};

  const submit_step = handleSubmit((fields) => {
    return submit({fields, next, setError, setVisible, notify});
  });

  return (
    <Dialog
      title={title}
      show={visible}
      backButton={!is_first_step && <BackButton onClick={previous} />}
      onHide={() => confirmClose({isDirty})}
      {...props}
      footer={
        <Navigation
          loading={isSubmitting}
          disabled={!isDirty}
          submit_step={submit_step}
          is_last_step={is_last_step}
          submit_action_title="Einladung an Mieter senden"
        />
      }>
      <div className={styles.container}>
        <InvitationSteps
          {...{
            errors,
            register,
            control,
            rental_contract,
            watch,
            step,
          }}
        />
        {steps.length > 1 && (
          <ProgressBar current_step={current_step} steps={steps} />
        )}
      </div>
    </Dialog>
  );
}

function InvitationSteps({
  errors,
  register,
  control,
  rental_contract,
  watch,
  step,
}) {
  return (
    <>
      {step.id === 'tenant' && (
        <TenantData
          rental_contract={rental_contract}
          errors={errors}
          register={register}
          control={control}
          watch={watch}
        />
      )}
      {step.id === 'property_owner' && (
        <PropertyOwnerData
          rental_contract={rental_contract}
          control={control}
          errors={errors}
        />
      )}
      {step.id === 'property' && (
        <RentalData
          rental_contract={rental_contract}
          errors={errors}
          register={register}
          watch={watch}
          control={control}
        />
      )}
    </>
  );
}

const pages = {
  tenant: {
    title: 'Mieter einladen',
    async submit({fields, setError, next, setVisible, notify}) {
      if (fields.tenant_provides_additional_data) {
        let deposit;

        try {
          deposit = await inviteTenant(fields);
        } catch (err) {
          showError(err, setError);
          return;
        }

        showCompletetionConfirmation({deposit, setVisible, notify});
      } else {
        try {
          await RPC('validateTenantStep', fields);
        } catch (err) {
          showError(err, setError);
          return;
        }

        next();
      }
    },
  },

  property_owner: {
    title: 'Eigentümer',
    async submit({fields, setError, next}) {
      try {
        await RPC('validatePropertyOwnerStep', fields);
      } catch (err) {
        showError(err, setError);
        return;
      }

      next();
    },
  },

  property: {
    title: 'Wohneinheit',
    async submit({fields, setError, setVisible, notify}) {
      const params = paramsFromFields(fields);
      let deposit;

      try {
        deposit = await inviteTenant(params);
      } catch (err) {
        showError(err, setError);
        return;
      }

      showCompletetionConfirmation({deposit, setVisible, notify});
    },
  },
};

function BackButton({onClick}) {
  return (
    <IconButton onClick={onClick} className={styles.back}>
      <ArrowLeftSvg />
    </IconButton>
  );
}

function showError(err, setError) {
  if (err.code === BAD_REQUEST) {
    if (
      err.data?.find(
        ({path, message}) =>
          path === '/deposit_amount_cents' &&
          message === VALIDATION_ERROR.deposit_exceeds_three_times_cold_rent,
      )
    ) {
      alert({
        text: 'Die Kaution darf maximal das dreifache der Kaltmiete betragen, bitte korrigieren Sie den eingetragenen Betrag.',
      });
      return;
    }

    if (
      err.data?.find(
        ({path, message}) =>
          path === '/deposit_amount_cents' &&
          message === VALIDATION_ERROR.number_too_high,
      )
    ) {
      alert({
        text: 'Wir unterstützen nur Kautionen bis 7.500 €',
      });
      return;
    }

    if (
      err.data?.find(
        ({path, message}) =>
          path === '/deposit_amount_cents' &&
          message === VALIDATION_ERROR.number_too_low,
      )
    ) {
      alert({
        text: 'Aktuell beträgt die Mindestkautionshöhe 500€. Wir arbeiten daran diese Begrenzung zu verringern. \nBei Rückfragen wenden Sie sich bitte an den Support.',
        email: 'partnersupport@getmomo.de',
      });
      return;
    }

    if (
      err.data?.find(
        ({path, message}) =>
          path === '/warm_rent_cents' &&
          message === VALIDATION_ERROR.number_too_low,
      )
    ) {
      alert({
        text: 'Die Kaltmiete darf nicht höher sein als die Warmmiete, bitte korrigieren Sie den eingegebenen Betrag.',
      });
      return;
    }

    if (err.data?.length) {
      setFormErrors({
        setError,
        errors: err.data,
      });
    } else if (err.message) {
      alert({title: err.message});
    }
  } else {
    handleError(err);
  }
}

function paramsFromFields({
  cold_rent_cents,
  deposit_amount_cents,
  property_owner,
  signed_date,
  start_date,
  property_type,
  storey,
  ...fields
}) {
  return {
    ...fields,
    cold_rent_cents,
    deposit_amount_cents,
    property_type,
    storey: property_type === PROPERTY_TYPES.apartment ? storey : undefined,
    signed_date: toLocaleDate(signed_date),
    start_date: toLocaleDate(start_date),
  };
}

function showCompletetionConfirmation({deposit, setVisible, notify}) {
  notify({text: 'Die Einladung wurde versendet'});
  setVisible(false);
  showDialog('deposit_guarantee_info', {deposit});
}
