import {useForm} from 'react-hook-form';
import TextInput from 'shared-web/components/TextInput.js';
import {RPC, NOT_FOUND} from 'shared/api.js';
import Button from 'shared-web/components/Button.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import {alert, handleError} from '../../effects.js';

import styles from './RequestResetPasswordForm.module.scss';

export default function RequestResetPasswordForm({setPasswordResetSent}) {
  const {
    register,
    formState: {isSubmitting, isValid},
    handleSubmit,
  } = useForm({mode: 'onChange'});

  const onSubmit = handleSubmit(({email_address}) =>
    proceed({email_address, setPasswordResetSent}),
  );

  return (
    <div>
      <p className={text_styles.h1_bright_bold_centered}>Passwort vergessen</p>

      <div className={styles.card}>
        <div className={styles.cardTop}>
          <p className={text_styles.body1_bold_centered}>
            Bitte geben Sie die E-Mail-Adresse ein, mit der Sie Ihr Konto
            erstellt haben.
          </p>
          <br />
          <p className={text_styles.body1_bold_centered}>
            Wir senden Ihnen einen Link, mit dem Sie Ihr Passwort zurücksetzen
            können.
          </p>
        </div>
        <form onSubmit={onSubmit}>
          <TextInput
            type="email"
            placeholder="E-Mail Adresse"
            className={styles.input}
            {...register('email_address', {required: true})}
          />
          <div className={styles.button_container}>
            <Button
              type="submit"
              title="Jetzt senden"
              loading={isSubmitting}
              inactive={!isValid}
              className={styles.button}
            />
          </div>
        </form>
      </div>
    </div>
  );
}

async function proceed({email_address, setPasswordResetSent}) {
  try {
    await RPC('requestPasswordReset', {email_address});
  } catch (err) {
    if (err.code === NOT_FOUND) {
      alert({
        text: 'Es war uns leider nicht möglich, Sie anhand der angegebenen Informationen zu identifizieren. Bitte überprüfen Sie die von Ihnen eingetragene E-Mail Adresse.',
        label: 'OK',
        removeBackdrop: true,
      });
    } else {
      handleError(err);
    }
    return;
  }
  setPasswordResetSent(true);
}
