import React, {forwardRef, useCallback, useMemo} from 'react';
import classNames from 'classnames';
import NumberFormat from 'react-number-format';
import {centsToPrice, eurosToCents} from 'shared/utils.js';

import text_styles from '../styles/text_styles.module.scss';

import input_styles from './Input.module.scss';
import styles from './PriceInput.module.scss';
import TextInput from './TextInput.js';

export default forwardRef(PriceInput);

function PriceInput(
  {
    label,
    inputClassName,
    className,
    error = '',
    value,
    onChange,
    readOnly,
    suffix,
    ...props
  },
  ref,
) {
  const value_euros = useMemo(() => {
    if (!value) return;
    return centsToPrice(value);
  }, [value]);
  const handleValueChange = useCallback(
    ({value, floatValue, ...values}) => {
      onChange(eurosToCents(floatValue));
    },
    [onChange],
  );

  return (
    <div
      className={classNames(
        readOnly ? input_styles.read_only_container : input_styles.container,
        className,
      )}>
      {label && (
        <div
          className={classNames(text_styles.caption_left, input_styles.label)}>
          {label}
        </div>
      )}
      <NumberFormat
        ref={ref}
        customInput={TextInput}
        className={classNames(styles.input, inputClassName)}
        placeholder={`0,00${suffix || ''}`}
        allowNegative={false}
        decimalScale={2}
        fixedDecimalScale
        decimalSeparator={','}
        thousandSeparator={'.'}
        allowedDecimalSeparators={','}
        value={value_euros}
        onValueChange={handleValueChange}
        readOnly={readOnly}
        suffix={suffix}
        {...props}
      />
      {!readOnly && (
        <p className={classNames(text_styles.caption_left, input_styles.error)}>
          {error}
        </p>
      )}
    </div>
  );
}
