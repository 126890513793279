import Button from 'shared-web/components/Button';

import {closeDialog} from '../../effects.js';

import {ReactComponent as AddIcon} from './add_circle_primary.svg';
import styles from './NewEntryButton.module.scss';

export default function NewEntryButton({
  title,
  dialog,
  onChange,
  dialog_props,
  showDialog,
}) {
  return (
    <Button
      onClick={() =>
        showDialog(dialog, {
          onSave: onChange,
          onExited: () => {
            closeDialog(dialog);
          },
          ...dialog_props,
        })
      }
      title={title}
      className={styles.add_new_item_button}
      Icon={AddIcon}
    />
  );
}
