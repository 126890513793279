import {NavLink} from 'react-router-dom';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import classNames from 'classnames';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import Icon from 'shared-web/components/Icon.js';

import styles from './SidebarItem.module.scss';

export default function SidebarItem({
  name,
  to,
  className,
  children,
  minimized,
}) {
  return (
    <NavLink
      className={(isActive) =>
        classNames(styles.nav_link, isActive && styles.selected, className)
      }
      isActive={(_match, location) => location.pathname.startsWith(to)}
      exact
      to={to}>
      {minimized ? (
        <OverlayTrigger
          key="right"
          placement="right"
          overlay={<Tooltip>{name}</Tooltip>}>
          <div className={styles.icon_container}>
            <Icon className={styles.icon}>{children}</Icon>
          </div>
        </OverlayTrigger>
      ) : (
        <>
          <div className={styles.icon_container}>
            <Icon className={styles.icon}>{children}</Icon>
          </div>
          <div className={classNames(text_styles.body2, styles.label)}>
            {name}
          </div>
        </>
      )}
    </NavLink>
  );
}
