import React from 'react';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import LegalLinks from 'shared-web/components/layout/LegalLinks.js';

import Sidebar from './Sidebar.js';
import styles from './Layout.module.scss';

export default function Layout({button, search, children}) {
  return (
    <div className={styles.container}>
      <Sidebar />
      <div className={styles.main}>
        {(button || search) && (
          <div className={styles.header}>
            {button}
            {search}
          </div>
        )}
        <div className={styles.content}>{children}</div>
        <div className={styles.footer}>
          <LegalLinks className={text_styles.caption_centered} />
        </div>
      </div>
    </div>
  );
}
