import React, {useMemo} from 'react';
import {useHistory} from 'react-router-dom';
import {Dropdown} from 'react-bootstrap';
import classNames from 'classnames';
import {formatDateShort} from 'shared/utils.js';
import Icon from 'shared-web/components/Icon.js';
import {MANAGER_PATHS} from 'shared/constants.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {useSelector} from 'react-redux';

import {showDialog, openTenantReport} from '../../effects.js';
import {ReactComponent as MoreVerticalSvg} from '../../assets/more_vertical.svg';
import {Table} from '../../components/Table/Table.js';
import {useTableContext} from '../../components/Table/context.js';

import styles from './PropertyOwnersTable.module.scss';
import {ReactComponent as ManagerOwnerSvg} from './manager-owner.svg';

const PLACEHOLDER_TEXT_NONE_CREATED = (
  <>
    Sie haben noch keine Eigentümer angelegt.
    <br />
    Sie können Eigentümer per Klick auf „Eigentümer erstellen“ anlegen.
  </>
);

const PLACEHOLDER_TEXT_NO_MATCHES = (
  <>
    Leider konnten wir keine Ergebnisse finden.
    <br />
    Bitte überprüfen Sie Ihre Suchanfrage.
  </>
);

const DEFAULT_SORTING = {column: 'created_at', direction: 'DESC'};

export default function PropertyOwnersTable({
  search_query,
  reset_table_trigger,
}) {
  const {refresh} = useSelector((state) => state.property_owners);
  const actions = usePropertyOwnersTableActions();
  return (
    <Table
      data_fetching_rpc_method_name="getPropertyOwners"
      columns={columns}
      actions={actions}
      search_query={search_query}
      default_action="onOpenPropertyOwner"
      refresh_trigger={refresh}
      reset_trigger={reset_table_trigger}
      no_match_label={PLACEHOLDER_TEXT_NO_MATCHES}
      empty_table_label={PLACEHOLDER_TEXT_NONE_CREATED}
      default_sorting={DEFAULT_SORTING}
    />
  );
}

/**
 * @type {Array<import('../../components/Table/context.js').ColumnDef>}
 */
export const columns = [
  {
    name: 'display_name',
    Header: 'Firma oder Name',
    Cell: DisplayNameCell,
    text_styles: 'body1_bold_left',
    sort: 'display_name',
    width: 'minmax(180px, 4fr)',
  },
  {
    name: 'contact_person',
    Header: 'Ansprechpartner',
    accessor: (row) => {
      if (!row.company_name) return '–';
      if (!row.first_name && !row.last_name) return '–';
      if (!row.first_name) return row.last_name;
      if (!row.last_name) return row.first_name;
      return `${row.first_name} ${row.last_name}`;
    },
    Cell: OneLineAccessorCell,
    sort: 'contact_last_name',
    width: 'minmax(180px, 4fr)',
  },
  {
    name: 'region',
    Header: 'Ort',
    accessor: ({region}) => region || '–',
    Cell: OneLineAccessorCell,
    sort: 'region',
    width: 'minmax(180px, 3fr)',
  },
  {
    name: 'number_of_active_deposit_guarantees',
    Header: 'Verknüpfte Mieter',
    width: 'max-content',
    sort: 'number_of_active_deposit_guarantees',
  },
  {
    name: 'created_at',
    Header: 'Erstellt',
    width: 'max-content',
    accessor: ({created_at}) => formatDateShort(created_at),
    sort: 'created_at',
  },
  {
    name: 'actions',
    Header: () => null,
    Cell: MenuCell,
    width: 'max-content',
    action: null,
  },
];

export function usePropertyOwnersTableActions() {
  const history = useHistory();
  const actions = useMemo(
    () => ({
      onOpenPropertyOwner: (property_owner) => {
        onOpenPropertyOwner(property_owner, history);
      },
      openTenantReport: (property_owner) => {
        openTenantReport(property_owner);
      },
    }),
    [history],
  );

  return actions;
}

function DisplayNameCell({value: row}) {
  const {
    display_name,
    is_manager,
    has_coowner,
    coowner_first_name,
    coowner_last_name,
  } = row;
  if (is_manager) {
    return (
      <div className={classNames(styles.display_name, styles.is_manager)}>
        <div className={styles.manager_display_name}>{display_name}</div>
        <Icon className={styles.manager_owner_icon}>
          <ManagerOwnerSvg />
        </Icon>
      </div>
    );
  }

  if (has_coowner) {
    return (
      <div className={styles.display_name}>
        <span
          className={classNames(
            text_styles.body1_bold_left,
            styles.owner_display_name_container,
          )}>
          <span className={styles.owner_display_name}>{display_name}</span>
          &nbsp;&
        </span>
        <br />
        <span
          className={classNames(
            text_styles.body1_italic,
            styles.owner_display_name_container,
          )}>
          <span className={styles.owner_display_name}>
            {coowner_first_name} {coowner_last_name}
          </span>
        </span>
      </div>
    );
  }

  return display_name || null;
}

function OneLineAccessorCell({value}) {
  return (
    <div className={styles.full_width_cell}>
      <span className={styles.one_line_ellipsing}>{value}</span>
    </div>
  );
}

function MenuCell({value: property_owner}) {
  const {actions} = useTableContext();
  const {openTenantReport} = actions;
  return (
    <Dropdown>
      <Dropdown.Toggle as="button">
        <Icon>
          <MoreVerticalSvg />
        </Icon>
      </Dropdown.Toggle>

      <Dropdown.Menu align="end">
        <Dropdown.Item
          as="button"
          disabled={property_owner.number_of_active_deposit_guarantees <= 0}
          onClick={() => openTenantReport(property_owner)}>
          Bericht generieren
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

function onOpenPropertyOwner(property_owner, history) {
  if (property_owner?.is_manager) {
    showDialog('property_owner_read_only', {property_owner});
  } else {
    history.push(`${MANAGER_PATHS.PropertyOwnersScreen}/${property_owner.id}`);
  }
}
