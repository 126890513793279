import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {Controller, useForm} from 'react-hook-form';
import classNames from 'classnames';
import {formatDate, formatPrice, formatShortId} from 'shared/utils.js';
import Input from 'shared-web/components/Input.js';
import {BAD_REQUEST, RPC} from 'shared/api.js';
import Button from 'shared-web/components/Button.js';
import text_styles from 'shared-web/styles/text_styles.module.scss';
import {setFormErrors} from 'shared-web/effects.js';
import SeparatorLine from 'shared-web/components/SeparatorLine';
import TextArea from 'shared-web/components/TextArea.js';
import Dialog from 'shared-web/components/dialog/Dialog.js';
import DateInput from 'shared-web/components/DateInput.js';
import {useNotify} from 'shared-web/NotifyProvider.js';
import IconButton from 'shared-web/components/IconButton.js';
import PriceInput from 'shared-web/components/PriceInput.js';
import {MANAGER_PATHS, TYPES} from 'shared/constants.js';
import {Dropdown} from 'react-bootstrap';
import {ReactComponent as ChevronDownSvg} from 'shared-web/components/chevron_down.svg';

import {updateCashDeposit} from '../../actions.js';
import {
  handleError,
  alert,
  confirmClose,
  deleteCashDeposit,
  openTaxSummary,
} from '../../effects.js';
import IbanDisplay from '../../components/IbanDisplay.js';
import {ReactComponent as TrashSvg} from '../../assets/trash.svg';

import styles from './CashDepositDialog.module.scss';
import IndividualTenantData from './IndividualTenantData.js';
import CompanyTenantData from './CompanyTenantData.js';

export default function CashDepositDialog({onHide, ...props}) {
  const [visible, setVisible] = useState(true);
  const {deposit_id: cash_deposit_id} = useParams();
  const [cash_deposit, setCashDeposit] = useState(null);
  const {notify} = useNotify();
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const history = useHistory();

  useEffect(() => {
    RPC('getCashDeposit', {id: cash_deposit_id})
      .then(setCashDeposit)
      .catch((err) => {
        handleError(err);
      });
  }, [cash_deposit_id]);

  const {
    register,
    control,
    reset,
    formState: {isSubmitting, isDirty, errors},
    handleSubmit,
    setError,
    watch,
    setValue,
  } = useForm({
    mode: 'onChange',
  });

  const tenant_type = watch('tenant_type');

  useEffect(() => {
    reset(cash_deposit);
  }, [reset, cash_deposit]);

  const onSubmit = handleSubmit((fields) =>
    proceed({fields, cash_deposit, setError, setVisible, notify}),
  );

  const cash_deposit_year = new Date(
    cash_deposit?.viban_allocated_at,
  ).getFullYear();

  const current_year = new Date().getFullYear();

  const actions = (
    <>
      <div className={styles.status}>
        <div className={styles.status_row}>
          <div className={text_styles.body1_bold_left}>Status:</div>
          <div className={text_styles.body2}>{cash_deposit?.status}</div>
        </div>
        <Button
          title="Speichern"
          loading={isSubmitting}
          onClick={onSubmit}
          disabled={!isDirty}
        />
      </div>

      <SeparatorLine />

      <div className={styles.actions}>
        <Button
          secondary
          className={styles.actions_button}
          textClassName={text_styles.body2_left}
          title="Auszahlung"
          onClick={() =>
            history.push(
              `${MANAGER_PATHS.InitiateCreditTransferDialog}/${cash_deposit_id}`,
            )
          }
        />

        <Dropdown
          className={styles.actions_button}
          onToggle={(isOpen) => {
            setIsToggleOpen(isOpen);
          }}>
          <Dropdown.Toggle
            as="button"
            className={classNames(
              text_styles.body2_left,
              text_styles.body2_bold,
              styles.actions_dropdown_toggle,
              isToggleOpen && styles.rotate,
            )}>
            <div>Einzelaufstellung</div>
            <ChevronDownSvg className={isToggleOpen && styles.rotate} />
          </Dropdown.Toggle>
          <Dropdown.Menu className={styles.actions_dropdown_menu}>
            {Array.from(
              {length: current_year - cash_deposit_year + 1},
              (_, i) => current_year - i,
            ).map((year) => (
              <Dropdown.Item
                as="button"
                key={year}
                onClick={() => openTaxSummary(cash_deposit_id, year)}>
                {year} -{' '}
                {current_year === year ? 'bis heute' : 'Jahresabschluss'}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Button
        secondary
        className={styles.actions_button}
        textClassName={text_styles.body2_left}
        title="Transaktionen"
        onClick={() =>
          history.push(
            `${MANAGER_PATHS.CashDepositTransactionsDialog}/${cash_deposit_id}`,
          )
        }
      />
    </>
  );

  function TrashElement() {
    return (
      <IconButton
        onClick={() =>
          deleteCashDeposit({cash_deposit, notify}).then((didDelete) => {
            if (didDelete) setVisible(false);
          })
        }>
        <TrashSvg />
      </IconButton>
    );
  }

  return (
    <Dialog
      title="Vertragsdaten"
      show={visible}
      additionalIcons={cash_deposit_id && [<TrashElement />]}
      onHide={() => confirmClose({isDirty})}
      {...props}>
      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        Angaben zur Wohneinheit
      </div>

      <Controller
        control={control}
        name="deposit_amount_cents"
        render={({field: {value, onChange, name}}) => (
          <PriceInput
            value={value}
            onChange={onChange}
            label="Kautionshöhe"
            error={errors[name]?.message}
            suffix={' €'}
            name={name}
          />
        )}
      />

      <div className={styles.row}>
        <Input
          label="Straße"
          {...register('street_name')}
          error={errors.street_name?.message}
        />
        <Input
          label="Hausnummer"
          {...register('street_number')}
          error={errors.street_number?.message}
        />
      </div>
      <div className={styles.row}>
        <Input
          label="PLZ"
          {...register('postal_code')}
          error={errors.postal_code?.message}
        />
        <Input
          label="Ort"
          {...register('region')}
          error={errors.region?.message}
        />
      </div>
      <Input value="Deutschland" label="Land" />

      <Controller
        name="start_date"
        control={control}
        render={({field: {value, onChange, name}}) => (
          <DateInput
            value={value}
            onChange={onChange}
            label="Einzugstermin laut Mietvertrag"
            error={errors[name]?.message}
            name={name}
          />
        )}
      />

      <SeparatorLine />

      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        Kautionskonto:
      </div>

      <Input
        value={formatPrice(cash_deposit?.account_balance_cents || 0)}
        label="Saldo inkl. geplanter Umsätze"
        readOnly
      />

      <IbanDisplay iban={cash_deposit?.viban} />

      <p
        className={classNames(
          text_styles.body1_bold_left,
          styles.deposit_iban_description,
        )}>
        Bitte verwenden Sie das Kautionskonto ausschließlich für die
        Kautionszahlungen von diesem Mietverhältnis.
      </p>

      <SeparatorLine />

      <div className={classNames(text_styles.body1_bold_left, styles.header)}>
        Beschreibung und Notizen
      </div>

      <Input
        label="Beschreibung Wohneinheit (optional)"
        className={styles.margin_right}
        error={errors.given_reference?.message}
        {...register('given_reference')}
      />

      <TextArea
        label="Notizen (optional)"
        placeholder="…"
        maxLength="500"
        error={errors.notes?.message}
        {...register('notes')}
      />

      <SeparatorLine />

      {tenant_type === TYPES.individual && (
        <>
          <IndividualTenantData
            errors={errors}
            control={control}
            register={register}
            watch={watch}
            setValue={setValue}
          />
        </>
      )}

      {tenant_type === TYPES.company && (
        <CompanyTenantData
          errors={errors}
          control={control}
          register={register}
        />
      )}

      <div className={styles.details}>
        <div className={text_styles.caption_left}>
          Erstellt am{' '}
          {cash_deposit?.created_at ? formatDate(cash_deposit.created_at) : ''}
        </div>
        <div className={text_styles.caption_left} data-testid="short_id">
          Momo-ID: {formatShortId(cash_deposit?.short_id)}
        </div>
      </div>
      {actions}
    </Dialog>
  );
}

async function proceed({fields, cash_deposit, setError, setVisible, notify}) {
  const {id} = cash_deposit;

  try {
    await updateCashDeposit({
      deposit_id: id,
      ...fields,
    });
  } catch (err) {
    if (err.code === BAD_REQUEST) {
      if (Array.isArray(err.data) && err.data.length > 0) {
        setFormErrors({
          setError,
          errors: err.data,
        });
        return;
      }
      if (err.message) {
        alert({title: err.message});
        return;
      }
    }
    handleError(err);
    return;
  }

  notify({text: 'Die Änderungen wurden gespeichert'});
  setVisible(false);
}
