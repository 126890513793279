import text_styles from 'shared-web/styles/text_styles.module.scss';
import Placeholder from 'shared-web/components/combobox/Placeholder.js';

import {showDialog} from '../effects.js';

import Combobox from './combobox/Combobox.js';
import NewEntryButton from './combobox/NewEntryButton.js';

export default function SelectPropertyOwner({
  value,
  onChange,
  selectedItemListener,
  error,
  label,
  readOnly,
  placeholder = '…',
}) {
  return (
    <Combobox
      name="property_owner"
      value={value}
      onChange={onChange}
      selectedItemListener={selectedItemListener}
      placeholder={placeholder}
      rpc_method="getPropertyOwnersOptions"
      DropdownItem={DropdownItem}
      itemToString={itemToString}
      dialog_name="property_owner"
      button_text="Eigentümer erstellen"
      error={error}
      button_new={
        <NewEntryButton
          title="Eigentümer erstellen"
          dialog="create_property_owner"
          onChange={(entity_id, ...args) => {
            selectedItemListener?.(entity_id);
            onChange(entity_id, ...args);
          }}
          showDialog={showDialog}
        />
      }
      NoResultsPlaceholder={NoResultsPlaceholder}
      NoSearchResultsPlaceholder={NoSearchResultsPlaceholder}
      label={label}
      readOnly={readOnly}
    />
  );
}

function DropdownItem({item}) {
  const suffix = item?.is_manager ? ' (wir selber)' : '';
  return (
    <p className={text_styles.body1_bold_left}>
      {item?.display_name}
      {suffix}
    </p>
  );
}

function itemToString(item) {
  const suffix = item?.is_manager ? ' (wir selber)' : '';
  return item?.id ? `${item.display_name}${suffix}` : '';
}

function NoResultsPlaceholder() {
  return (
    <Placeholder>
      <p>Es wurden noch keine Eigentümer angelegt.</p>
      <p>
        Sie können per Klick auf „Eigentümer erstellen“ den ersten Eigentümer
        anlegen.
      </p>
    </Placeholder>
  );
}

function NoSearchResultsPlaceholder({search_query}) {
  return (
    <Placeholder>
      <p>Wir konnten keine Eigentümer beginnend mit „{search_query}“ finden.</p>
      <p>
        Sie können per Klick auf „Eigentümer erstellen“ und diese später im
        Menüpunkt Eigentümer verwalten.
      </p>
    </Placeholder>
  );
}
