import {Controller} from 'react-hook-form';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import SelectPropertyOwner from '../../components/SelectPropertyOwner.js';

export default function PropertyOwnerData({rental_contract, control, errors}) {
  return (
    <>
      <Controller
        control={control}
        name="property_owner_id"
        render={({field: {value, onChange}}) => {
          return (
            <SelectPropertyOwner
              label="Eigentümer"
              placeholder="bitte hier klicken"
              value={value}
              onChange={onChange}
              error={errors.property_owner_id?.message}
              readOnly={!!rental_contract?.property_owner}
            />
          );
        }}
      />

      <div className={text_styles.body1_bold_left}>
        Die Adresse des Eigentümers wird nicht mit dem Mieter geteilt. Auf dem
        vom Mieter unterzeichneten Avalkreditvertrag (Bürgschaftsauftrag) wird
        nur der Name des Eigentümers angezeigt.
      </div>
    </>
  );
}
