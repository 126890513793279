import React from 'react';
import classNames from 'classnames/bind.js';
import {Form} from 'react-bootstrap';
import text_styles from 'shared-web/styles/text_styles.module.scss';

import styles from './SwitchToggle.module.scss';

export default function SwitchToggle({id, label, value, className, ...props}) {
  return (
    <Form.Group className={classNames(styles.group, className)} controlId={id}>
      <Form.Check
        id={id}
        type="switch"
        checked={value}
        className={styles.check}
        {...props}
      />
      <Form.Label className={classNames(styles.text, text_styles.body3)}>
        {label}
      </Form.Label>
    </Form.Group>
  );
}
